<template>
  <n-grid cols="1 s:10" responsive="screen" style="padding-top: 30px">
    <n-gi span="8" offset="0 s:1"
      ><Table
        :data="data"
        :fieldsToHide="['id']"
        :globalActions="maintenanceGlobalActions"
        :itemsActions="maintenanceItemActions"
        :filterableFields="[t('commons.labels.type')]" /></n-gi
  ></n-grid>
</template>
<script>
import { defineComponent, computed, onMounted } from "vue";
import { AddOutline as NewItemIcon, PencilOutline as EditIcon } from "@vicons/ionicons5";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { NGrid, NGi } from "naive-ui";
import Table from "@/components/TableBeta.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { Table, NGrid, NGi },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const router = useRouter();
    const store = useStore();

    onMounted(async () => {
      await store.dispatch("maintainer_parameters/fillParameters");
    });

    const dataRef = computed(() =>
      store.state.maintainer_parameters.parameters.map((parameter) => {
        return {
          id: parameter.id,
          [t("commons.labels.name")]: parameter.name,
          [t("commons.labels.type")]: t(`commons.inputTypes.${parameter.type}`),
          [t("commons.labels.value")]: parameter.value,
        };
      })
    );

    return {
      t,
      maintenanceGlobalActions: computed(() => {
        return [
          {
            label: t("commons.actions.add"),
            key: "add",
            icon: NewItemIcon,
            props: {
              onClick: () => {
                router.push({ name: "create-parameter" });
              },
            },
          },
        ];
      }),
      maintenanceItemActions: (item) => {
        let actions = [];
        actions.push({
          label: t("commons.actions.update"),
          key: "update",
          icon: EditIcon,
          props: {
            onClick: () => {
              router.push({
                name: "update-parameter",
                params: { id: item.id },
              });
            },
          },
        });
        return actions;
      },
      data: dataRef,
    };
  },
});
</script>
